html, body {
  scroll-behavior: smooth;
}

@media only screen and (min-device-width: 480px) {
  .slick-slide {
    padding-left: 20px;
    padding-right: 20px;
  }
}
