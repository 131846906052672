@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Recursive;
    src: url('fonts/Recursive-VariableFont_CASL,CRSV,MONO,slnt,wght.ttf') format('truetype');
    font-variation-settings: "CASL" 1;
}

@layer components {
    .header-l {
        @apply text-center font-recursive text-5xl md:text-8xl lg:text-10xl font-extrablack
    }

    .header-m {
        @apply font-recursive text-center font-extrablack text-5xl
    }

    .content-m {
        @apply mx-auto text-center text-xl font-bold max-w-screen-lg pt-8 leading-loose
    }

    .content-l {
        @apply text-center text-3xl mx-auto font-bold max-w-screen-lg py-8 leading-loose
    }

    .block-padding {
        @apply pt-24 pb-24
    }

    .block-padding-empty {
        @apply pt-24 pb-96
    }

    .block-outer-margin {
        @apply mx-14
    }

    .button-m {
        @apply bg-white-900 py-2 px-5 rounded-3xl w-fit font-recursive
    }

    .input-field {
        @apply

        text-xl
        w-full

        focus:border-secondary
        focus:ring-secondary

        disabled:bg-slate-50
        disabled:text-slate-500
        disabled:border-slate-200
        disabled:shadow-none

        invalid:border-pink-500
        invalid:text-pink-600

        focus:invalid:border-primary
        focus:invalid:ring-primary
    }
}

@layer utilities {
    @variants responsive {
        .masonry {
            column-gap: 1.5em;
            column-count: 1;
        }
        .masonry-sm {
            column-gap: 1.5em;
            column-count: 2;
        }
        .masonry-md {
            column-gap: 1.5em;
            column-count: 3;
        }
        .break-inside {
            break-inside: avoid;
        }
    }
}
